import React from "react"
import { graphql } from "gatsby"

import Layout from './../components/Layout';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { html, frontmatter } = markdownRemark

  return (
      <Layout defaultHeader={frontmatter.title}>
        <div
            id="doc-content"
            className="underlined-h3"
            style={{
                maxWidth: 600,
                margin: '0 auto'
            }}
            dangerouslySetInnerHTML={{ __html: html }}
        />
      </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }
  }
`